<template>

  <div class="row">
    <b-modal :id="formId" :size="formWidth">
      <template #modal-title>
        <div v-if="formState=='Update'">Update Sites #{{ formData.id }}</div>
        <div v-if="formState=='Create'">Create Sites</div>
      </template>

      <EditSites
          v-if="formState=='Update'"
          :key="formKey"
          :champsAfficher="champsAfficher"
          :clientsData="clientsData"
          :data="formData"
          :gridApi="formGridApi"
          :mapkey="$route.meta.key"
          :modalFormId="formId"
          :zonesData="zonesData"
          @close="closeForm"
      />


      <CreateSites
          v-if="formState=='Create'"
          :key="formKey"
          :champsAfficher="champsAfficher"
          :clientsData="clientsData"
          :gridApi="formGridApi"
          :modalFormId="formId"
          :zonesData="zonesData"
          @close="closeForm"
      />

      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>

    <div class="childBlock">
      <AgGridTable
          :key="tableKey"
          :cacheBlockSize="cacheBlockSize"
          :columnDefs="columnDefs"
          :extrasData="extrasData"
          :maxBlocksInCache="maxBlocksInCache"
          :pagination="pagination"
          :paginationPageSize="paginationPageSize"
          :rowData="rowData"
          :rowModelType="rowModelType"
          :url="url"
          className="ag-theme-alpine"
          domLayout='autoHeight'
          rowSelection="multiple"
          @gridReady="onGridReady"

      >
        <template #header_buttons>
          <div v-if="type !='Sites'" class="btn btn-primary" @click="openCreate"><i
              class="fa fa-plus"></i>
            Ajouter un site
          </div>
        </template>

      </AgGridTable>

    </div>
  </div>
</template>


<script>
import {mapGetters} from 'vuex';

import moment from 'moment'


export default {
  name: 'SitesView',
  components: {
    DataTable: () => import( "@/components/DataTable.vue"),
    AgGridTable: () => import("@/components/AgGridTable.vue"),
    CreateSites: () => import( "./CreateSites.vue"),
    EditSites: () => import( "./EditSites.vue"),
    DataModal: () => import("@/components/DataModal.vue"),
    AgGridBtnClicked: () => import("@/components/AgGridBtnClicked.vue"),
    CustomFiltre: () => import("@/components/CustomFiltre.vue"),
  },
  props: ['type'],
  data() {

    return {
      champsAfficher: [
        //LISTE DES CHAMP à MASQUER
      ],
      formId: "sites",
      formState: "",
      formData: {},
      formWidth: 'lg',
      formGridApi: {},
      formKey: 0,
      tableKey: 0,
      url: 'http://127.0.0.1:8000/api/sites-Aggrid1',
      table: 'sites',
      clientsData: [],
      typesget: [],
      typeselectionner: [],
      zonesData: [],
      requette: 2,
      // columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      rowModelType: null,
      pagination: true,
      paginationPageSize: 100,
      cacheBlockSize: 10,
      maxBlocksInCache: 1,
    }
  },

  computed: {
    ...mapGetters('app', ['subDomain', 'domain']),
    $routeData: function () {
      let router = {meta: {}};
      try {
        if (typeof window.routeData != 'undefined') {
          router = window.routeData
        }
      } catch (e) {
      }

      return router;
    },
    taille: function () {
      let result = 'col-sm-12'
      if (this.filtre) {
        result = 'col-sm-9'
      }
      return result
    },
    columnDefs: function () {
      let columnDefs = [
        {
          field: "sites.id",
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {suppressAndOrCondition: true,},
          hide: true,
          headerName: '#Id',
        },
        {
          field: null,
          headerName: '',
          suppressCellSelection: true,
          minWidth: 80, maxWidth: 80,
          hide: this.isShow("interne"),
          // hide: true,
          pinned: 'left',
          cellRendererSelector: params => {
            return {
              component: 'AgGridBtnClicked',
              params: {
                clicked: field => {
                  // if (this.isShow("zone_id")) {
                  this.showForm('Update', field, params.api, 'xl')
                  // } else {
                  // this.showForm('Update', field, params.api)
                  // }
                },
                render: `<div class="btn btn-primary  d-flex justify-content-center align-items-center" style="width:100%;height:100%;color:#fff;border-radius:5px;text-align:center;cursor:pointer">  <i class="fa-solid fa-pen-to-square "></i></div>`
              }
            };
          },

        },
        {
          field: 'date_debut',
          headerName: '',
          hide: true,
        },
        {
          field: 'date_fin',
          headerName: '',
          hide: true,
        },
        {
          field: "sites.libelle",
          sortable: true,
          filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
          headerName: 'libelle',
        },
        {
          headerName: 'client',
          field: 'clients.libelle',
          join: {
            table: 'clients',
            champ1: 'sites.client_id',
            champ2: 'clients.id',
            operateur: '='
          }
        },
        {
          headerName: 'zone',
          field: 'zones.libelle',
          join: {
            table: 'zones',
            champ1: 'sites.zone_id',
            champ2: 'zones.id',
            operateur: '='
          }
        },
        {
          field: "sites.created_at",
          sortable: true,
          filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
          headerName: 'Créer le',
          valueFormatter: params => {
            let retour = params.value
            try {
              retour = moment(params.value).format('DD/MM/YYYY à HH:mm')
            } catch (e) {

            }
            return retour
          }
        },
        {
          hide: true,
          suppressColumnsToolPanel: true,

          headerName: 'type',
          field: 'typessites.id',
          filter: "FiltreEntete",
          filterParams: {
            url: this.axios.defaults.baseURL + '/api/typessites-Aggrid1',
            columnDefs: [
              {
                field: "",
                sortable: true,
                filter: "agTextColumnFilter",
                filterParams: {suppressAndOrCondition: true},
                headerName: "",
                cellStyle: {fontSize: '11px'},
                valueFormatter: (params) => {
                  let retour = "";
                  try {
                    return `${params.data["libelle"]}`;
                  } catch (e) {
                  }
                  return retour;
                },
              },
            ],
            filterFields: ['libelle'],
          },
          join: {
            table: 'typessites',
            champ1: 'sites.typessite_id',
            champ2: 'typessites.id',
            operateur: '='
          }
        },
        {
          suppressColumnsToolPanel: true,
          headerName: 'type',
          field: 'typessites.libelle',
        },
      ]
      return columnDefs;
    },
    extrasData: function () {
      let params = {};
      this.tableKey++;
      return {
        baseFilter: params,
        typeselectionner: this.typeselectionner,
      }


    },
  },
  watch: {
    '$route': {
      handler: function (after, before) {
        this.gridApi.setFilterModel(null)
        this.gridApi.refreshServerSide()
        this.tableKey++
      },
      deep: true
    },
  },
  created() {
    this.url = this.axios.defaults.baseURL + '/api/sites-Aggrid1',
        this.formId = this.table + "_" + Date.now()
    this.rowBuffer = 0;
    this.rowModelType = 'serverSide';
    this.cacheBlockSize = 50;
    this.maxBlocksInCache = 2;


  },
  beforeMount() {
    // this.columnDefs =
    //     [


    //     ];


  },
  mounted() {
    if (this.requette > 0) {
      // this.$store.commit('setIsLoading', true)
    }
    // this.getclients();
    // this.getzones();
    this.typesget = this.$route.meta.typesGet
    console.log('this.typesget', this.$route.meta.typesGet);
  },
  methods: {
    havecustomfilter(fieldName) {
      // METHODE UTILISER DANS (HIDE) POUR PERMETTRE DE MASQUER LES CHAMPS MIS DANS LE TABLEAU champsAfficher
      if (this.champsAfficher.includes(fieldName)) {
        return null
      } else {
        return "CustomFiltre"
      }
    },
    isShow(fieldName) {
      // METHODE UTILISER DANS (HIDE) POUR PERMETTRE DE MASQUER LES CHAMPS MIS DANS LE TABLEAU champsAfficher
      return this.champsAfficher.includes(fieldName); // si le champ existe return prend la valeur *true*
    },
    openCreate() {
      this.showForm('Create', {}, this.gridApi)
    },
    closeForm() {
      try {
        this.gridApi.refreshServerSide()
      } catch (e) {

      }
    },
    showForm(type, data, gridApi, width = 'lg') {
      this.formKey++
      this.formWidth = width
      this.formState = type
      this.formData = data
      this.formGridApi = gridApi
      this.$bvModal.show(this.formId)
    },
    onGridReady(params) {
      console.log('on demarre', params)
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.isLoading = false
    },
    getclients() {
      this.axios.get('/api/clients').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // // this.$store.commit('setIsLoading', false)
        }
        this.clientsData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },

    getzones() {
      this.axios.get('/api/zones').then((response) => {
        this.requette--
        if (this.requette == 0) {
          // // this.$store.commit('setIsLoading', false)
        }
        this.zonesData = response.data

      }).catch(error => {
        console.log(error.response.data)
        // // this.$store.commit('setIsLoading', false)
        this.$toast.error('Erreur survenue lors de la récuperation')
      })
    },
    typeselect(type) {
      if (this.typeselectionner.includes(type)) {
        // type is already selected, so we want to deselect it
        const index = this.typeselectionner.indexOf(type);
        if (index !== -1) {
          this.typeselectionner.splice(index, 1); // Remove the zone from the array
        }
      } else {
        // type is not selected, so we want to select it
        this.typeselectionner.push(type);
      }

    },
  }
}
</script>
<style>
.ag-root-wrapper {
  border-radius: 5px
}

.childBlock {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 95%;
  margin: 10px auto;
}

.newButton {
  text-align: center;
  margin: 0 auto;
  position: absolute;
  top: 15px;
  right: 30px;
  border-radius: 5px;
  padding: 10px;
  background: #0004ff;
  color: #fff;
}
</style>
